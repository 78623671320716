<template>
    <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-center">
                           Mi Perfil
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="space-around" >
                    <v-col cols="12" md="5" >
                        
                        <employee-form  ref="EmployeeForm" :values="form" :serverErrors="serverErrors"></employee-form>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5" class="pb-0">
                        <secondaryButton
                            class=""
                            :props="toPositionBtn"
                        ></secondaryButton>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <secondaryButton
                            class=""
                            :props="toChangePasswordBtn"
                        ></secondaryButton>
                    </v-col>
                </v-row>

                

            
                
            </div>

          
        </template>
    </contentCardMobile>


</template>

<script>
import EmployeeForm from "./Form.vue";
import Utils from '../../../helpers/Utils';
export default {
    components: {
        'employee-form': EmployeeForm,
    },
	data(){
		return {
            serverErrors:{},
            toChangePasswordBtn:{
                text: "Cambiar contraseña",
                icon: "",
                to: "MyInfoChangePassword",
                block: true,
                color: 'primary',
                height: "35",
                
                click: ()=>{}
            },
            toPositionBtn:{
                text: "Descripción de puesto",
                icon: "",
                to: "PositionInformation",
                block: true,
                color: 'primary',
                height: "35",
                
                click: ()=>{}
            },
            
            loading: false,
            form: {
                /**GENERAL */
                photo_url: "",
                admissionYear: "",
                employe_number: '',
                name: '',
                email: '',
                business_email: '',
                profession: '',
                birthplace: '',
                birthdate: '',
                gender: '',
                marital_status: '',
                curp: '',
                rfc: '',
                infonavit_number: '',
                fonacot_number: '',
                nss: '',
                phone: '',
                blood_type: '',
                diseases: [],
                country: 'MX',
                street: '',
                ext_number: '',
                int_number: '',
                zip_code: '',
                neighborhood: '',
                city: '',
                state: '',
                emergency_contact: '',
                emergency_number: '',
                payee_name: '',
                payee_family_phone: '',
                payee_family_relationship: '',
                photo_file: [],
                has_childs: false,
                children: [],

                

              

            },
            
           
		}
    },
    watch: {
        
     
      
    },
    mounted(){
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.index();
    },
	methods: {
        onActionConfirmed() {
            setTimeout(() => {
                this.$refs.swipeButton.reset();
            }, 1000);
        },
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            // this.$store.state.loading = true;
			this.$api.employeeEmployee.myInfo()
			.then((response) => {
                this.form = {
                    ...this.form,
                    employe_number: response.data.employe_number,
                    name: response.data.name,
                    email: response.data.email,
                    business_email: response.data.business_email??"",
                    profession: response.data.profession??"",
                    birthplace: response.data.birthplace??"",
                    birthdate: response.data.birthdate ? Utils.globalDateFormat(response.data.birthdate) :"",
                    gender: response.data.gender??"",
                    marital_status: response.data.marital_status??"",
                    curp: response.data.curp,
                    rfc: response.data.rfc,
                    infonavit_number: response.data.infonavit_number??"",
                    fonacot_number: response.data.fonacot_number??"",
                    nss: response.data.nss,
                    phone: response.data.phone,
                    blood_type: response.data.blood_type??"",
                    diseases: response.data.diseases,
                    country: response.data.country,
                    street: response.data.street,
                    ext_number: response.data.ext_number,
                    int_number: response.data.int_number,
                    zip_code: response.data.zip_code,
                    neighborhood: response.data.neighborhood,
                    city: response.data.city,
                    state: response.data.state,
                    emergency_contact: response.data.emergency_contact,
                    emergency_number: response.data.emergency_number,
                    payee_name: response.data.payee_name??"",
                    payee_family_phone: response.data.payee_family_phone??"",
                    payee_family_relationship: response.data.payee_family_relationship??"",
                    photo_file: [],
                    photo_url: response.data.photo_url, 
                    has_childs: false,
                    children: [],
                };

                this.form.admissionYear = response.data?.contract?.admission_date.split("-")[0]??"";

                if("children" in response.data){
                    if(response.data.children.length > 0){
                        this.form.has_childs = true;

                        response.data.children.forEach(item => {
                            this.form.children.push(
                                {birthdate: Utils.globalDateFormat(item.birthdate) , gender: item.gender, id: item.id}
                            )
                        });
                    }
                }
                //part 1
                // this.form = {
                //     ...this.form, 
                //     photo_file:[], 
                //     photo_url: response.data.photo_url, 
                //     has_childs: false,
                //     children: []
                // };
                

               
				//console.log(this.id,this.form, 'here');
                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                // this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },
    },
}
</script>

<style lang="scss">
    
    

        
</style>