<template>
    <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
            <div class="">
                <v-row justify="space-around">
                   
                    <v-col cols="12" md="5" class="pb-0 pt-1"
                        >
                            <v-chip-group
                            mandatory
                            active-class="employee-payroll-chip-selected"
                            v-model="selectedYear"
                            >
                            
                                <v-chip
                                    v-for="(item, index) in years" :key="index"
                                    :value="item.value"
                                    class="employee-payroll-chip"
                                >
                                    <span class="">{{item.text}}</span>
                                </v-chip>
                               
                            </v-chip-group>
                    </v-col>
                </v-row>
            

                <v-row justify="space-around">
                    <v-col
                        cols="12"
                        md="5"
                        >
                            <div class="text-center" v-if="loading">
                                <v-progress-circular
                                    indeterminate
                                    color="primary"
                                ></v-progress-circular>
                            </div>

                            <div v-if="!loading && payrollData.length == 0" class="text-center">
                                <span>Sin resultados</span>
                            </div>
                            <span>RECIBOS DE NÓMINA {{typeTextPayroll}} DE {{selectedYear}}</span>
                            <div v-for="(item, index) in payrollData" :key="index" >
                                <div v-if="item.records.length > 0">
                                    <div  class="employee-payroll-container-item">
                                    
                                    <span class="employee-payroll-item-title">{{item.month_name}} {{selectedYear}}</span>
                                        
                                        <div class="employee-payroll-item mb-3"  v-for="(record, index) in item.records" :key="index">
                                            <!-- <span class="employee-payroll-item-subtitle">{{record.payroll_type}}</span> -->
                                            <div class="employee-payroll-item-details">
                                                <span>{{ut.globalDateFormat(record.start_date)}} - {{ut.globalDateFormat(record.end_date)}} <span :class="`employee-payroll-item-type employee-payroll-item-type-${record.type}`">{{record.type}}</span></span>
                                                <v-icon  color="primary" class="``" @click="open(record.file_url)">mdi-file-document-outline</v-icon>
                                            </div>
                                            <v-divider></v-divider>
                                        </div>
                                    
                                    </div>
                                </div>
                                
                            </div>
                            
                    </v-col>
                </v-row>
            </div>
        </template>
    </contentCardMobile>
</template>

<script>
// import PageStateManager from '../../../helpers/PageStateManager';
import Utils from '../../../helpers/Utils';
export default {
   
	data(){
		return {
            loading: true,
            years:[],
            selectedYear: '',
            typeTextPayroll: '',
            chipSelected: 0,
            payrollData: [],
            rawData: [],
            ut: Utils,
		}
    },
    watch: {
        
        [`selectedYear`]: function () {
            this.index();
        },
     
      
    },
    mounted(){
        //    this.index();
        this.loadYears();
    },
	methods: {
        open(fileUrl){
            window.open(fileUrl)
        },
        loadYears(){
            this.loading = true;
            this.$api.employeeEmployee.myInfo()
			.then((response) => {
                
                let initialYear = response.data?.contract?.admission_date.split("-")[0]??"";
                if(initialYear < 2025)
                    initialYear = 2025
                this.years = [];
                let now = new Date();
                now.setHours(0,0,0,0)
                const year = now.getFullYear();
                if(this.selectedYear == "")
                    this.selectedYear = ""+year;
                for (let i = initialYear; i <= year; i++) {
                    this.years.push({text: ""+i, value: ""+i});
                }
                this.typeTextPayroll = response.data.contract.payroll_type
                this.index();
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				
                // this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },
        index(){
            if(this.selectedYear == undefined && this.selectedYear == null && this.selectedYear == "")
                return;
                
            
            this.payrollData = [];
            this.loading = true;
            this.$api.employeeEmployee.getPaychecks(this.selectedYear)
				.then((resp) =>{
                    this.rawData = JSON.parse(JSON.stringify(resp.data));
					this.payrollData = this.process(resp.data);

                    console.log(this.payrollData, "process data")
					
				})
				.catch((error) =>{
					console.log(error);
				})
				.finally(() => {
					// this.tableProps.loading = false;
                    this.loading = false;
				})
			;

          
        },
        process(info){
            // let data = info.sort((a, b) => {
            // if (a.date < b.date)
            //     return -1;
            // if (a.date > b.date)
            //     return 1;
            // return 0;
            // })
            let valid = false;
            info.forEach(itm => {
                if(itm.records.length > 0)
                    valid = true;
            });

            if(!valid)
                return [];

            for (let i = 0; i < info.length; i++) {

                info[i].records = info[i].records.sort((a, b) => {
                if (a.start_date < b.start_date)
                    return -1;
                if (a.start_date > b.start_date)
                    return 1;
                return 0;
                })
                
            }


            return info;

        },
      
    },
}
</script>

<style lang="scss"> 
    .employee-payroll-chip{
        background-color: #d6d6d7 !important;
        &-selected{
            background-color: var(--v-primary-base) !important;
            color: var(--v-buttonTextDefault-base) !important;
        }
    }

    .employee-payroll{
        &-container-item{
            display: flex;
            flex-direction: column;
        }

        &-item-title{
            font-weight: 600;
            font-size: 0.85rem;
        }
        &-item-subtitle{
            color: black;
            font-weight: 600;
            font-size: 0.8rem;
        }
        &-item-details{
            display: flex;
            justify-content: space-between;
            font-size: 0.7rem;
            :first-child{
                overflow-wrap: anywhere;
            }
        }

        &-item-type{
            padding: 1px 6px;
            border-radius: 6px;
            font-weight: 600;
        }
        &-item-type-FISCAL{
            border: 2px green solid;
            color: green;
        }
         &-item-type-INTEGRADORA{
            border: 2px blue solid;
            color: blue;
        }
    }
</style>