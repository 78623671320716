<template>
    <div>
        
        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="Empresa"
                    :valueInput="values.company"
                    
                    @valueChange="(v) => {values.company = v.trim(); delete serverErrors.company}"
                    :errorMessages="serverErrors.company"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="Departamento"
                    :valueInput="values.department"
                    
                    @valueChange="(v) => {values.department = v.trim(); delete serverErrors.department}"
                    :errorMessages="serverErrors.department"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

     

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="Requiere viajar"
                    :valueInput="values.requires_travel"
                    
                    @valueChange="(v) => {values.requires_travel = v.trim(); delete serverErrors.requires_travel}"
                    :errorMessages="serverErrors.requires_travel"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>
        
        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="A cargo de gente"
                    :valueInput="values.have_persons_in_charge"
                    
                    @valueChange="(v) => {values.have_persons_in_charge = v.trim(); delete serverErrors.have_persons_in_charge}"
                    :errorMessages="serverErrors.have_persons_in_charge"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="Requiere de inglés"
                    :valueInput="values.requires_english"
                    
                    @valueChange="(v) => {values.requires_english = v.trim(); delete serverErrors.requires_english}"
                    :errorMessages="serverErrors.requires_english"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    name="p1"
                    label="Nivel de inglés requerido"
                    :valueInput="values.english_level"
                    
                    @valueChange="(v) => {values.english_level = v.trim(); delete serverErrors.english_level}"
                    :errorMessages="serverErrors.english_level"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                    <textAreaForm
                        @valueChange="(v) => {values.purpose = v.trim(); delete serverErrors.purpose}" 
                        :valueInput="values.purpose" 
                        label="Objetivo"
                        :errorMessages="serverErrors.purpose"
                        :readonly="true"
                        :rows="1"
                    ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    label="Reportar a"
                    :valueInput="values.report_to"
                    
                    @valueChange="(v) => {values.report_to = v.trim(); delete serverErrors.report_to}"
                    :errorMessages="serverErrors.report_to"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    label="Supervisa a"
                    :valueInput="values.supervise_to"
                    
                    @valueChange="(v) => {values.supervise_to = v.trim(); delete serverErrors.supervise_to}"
                    :errorMessages="serverErrors.supervise_to"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    label="Grado de estudios"
                    :valueInput="values.studies"
                    
                    @valueChange="(v) => {values.studies = v.trim(); delete serverErrors.studies}"
                    :errorMessages="serverErrors.studies"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    label="Experiencia previa (años)"
                    :valueInput="values.experience"
                    
                    @valueChange="(v) => {values.experience = v.trim(); delete serverErrors.experience}"
                    :errorMessages="serverErrors.experience"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="py-1">
                <textFieldForm
                    label="Edad mínima para el puesto"
                    :valueInput="values.minimum_age"
                    
                    @valueChange="(v) => {values.minimum_age = v.trim(); delete serverErrors.minimum_age}"
                    :errorMessages="serverErrors.minimum_age"
                    :readonly="true"
                ></textFieldForm>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                
                <textAreaForm
                    @valueChange="(v) => {values.background = v.trim(); delete serverErrors.background}" 
                    :valueInput="values.background" 
                    label="Competencias"
                    :errorMessages="serverErrors.background"
                    :readonly="true"
                    :rows="1"
                ></textAreaForm>
                
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="(v) => {values.abilities = v.trim(); delete serverErrors.abilities}" 
                    :valueInput="values.abilities" 
                    label="Habilidades"
                    :errorMessages="serverErrors.abilities"
                    :readonly="true"
                    rows="1"
                    ref="messageInput"
                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="(v) => {values.responsibilities = v.trim(); delete serverErrors.responsibilities}" 
                    :valueInput="values.responsibilities" 
                    label="Responsabilidades"
                    :errorMessages="serverErrors.responsibilities"
                    :readonly="true"
                    :rows="1"

                ></textAreaForm>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <textAreaForm
                    @valueChange="(v) => {values.authority = v.trim(); delete serverErrors.authority}" 
                    :valueInput="values.authority" 
                    label="Tiene autoridad de"
                    :errorMessages="serverErrors.authority"
                    :readonly="true"
                    :rows="1"
                    
                    
                ></textAreaForm>
            </v-col>
        </v-row>


    </div>
</template>

<script>
/* eslint-disable */
export default {
    props: ['values', 'serverErrors'],
    components: {
    },
    data(){
        return {
           
           
            serverItems: {
                studies: [
                    {text: "Primaria", value: "Primaria"}, 
                    {text: 'Secundaria', value: 'Secundaria'}, 
                    {text: 'Preparatoria', value: 'Preparatoria'},
                    {text: 'Licenciatura', value: 'Licenciatura'},
                    {text: 'Posgrado', value: 'Posgrado'}
                ]
            },

          

          
        }
    },
    
    mounted(){
       
    },
    methods: {
      
       
        getCompanies()
        {
            this.loadingCompanies = true;
            this.$api.user.getUserBusinessSelect()
                .then((resp) =>{
                    //console.log(resp, "desde form");
                    this.serverItems.companies = resp.data.map((item) => {
                        return {text: item.name, value: item.id};
                    });
                })
                .catch((error) =>{
                    console.log(error, "error desde form areas");
                })
                .finally(() => {
                    this.loadingCompanies = false;
                })
            ;
        },

    
    }
}
</script>

<style lang="scss">
    .myProfile{
        &-photo-container{
            text-align: center;
            position: relative;
        }

        &-photo{

            width: auto;
            // height: 200px;
            text-align: center;
            object-position: 20% 0%;
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;

        }
    }

    .image-cropped-transform {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 55%;
        transform: scale(0.5) translate(0, 5%);
    }
</style>