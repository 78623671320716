<template>
    <contentCardMobile :loading="loading" :elevation="0" classCard="px-0 mx-0 py-0" cardColor="transparent">
        <template slot="cardContent">
			<div>
                <v-row justify="space-around">
                    <v-col cols="12" md="5" >
                        <div class="font-weight-bold body-1 text-left">
                           {{form.name}}
                        </div>
                        <div class="positionInfo-subtitles text-left">
                           <span class="font-weight-bold">Clave</span> {{form.code}} | <span class="font-weight-bold">Emisión</span> {{ut.globalDateFormat(form.emission_date)}} | <span class="font-weight-bold">Revisión</span> {{form.revision}}
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="space-around" >
                    <v-col cols="12" md="5" >
                        
                        <position-form  ref="PositionForm" :values="form" :serverErrors="serverErrors"></position-form>
                    </v-col>
                </v-row>

                <v-row justify="space-around" >
                    <v-col cols="12" md="5" >
                        <div class="positionInfo-footer">
                            <div class="positionInfo-footer-item">
                                <span class="pr-3">
                                    Elaborado por {{form.made_by}}
                                </span>
                                <span>
                                    {{form.format_key}}
                                </span>
                            </div>

                            <div class="positionInfo-footer-item">
                                <span class="pr-3">
                                    Revisado por {{form.approved_by}}
                                </span>
                                <span>
                                    {{form.format_revision}}
                                </span>
                            </div>
                        </div>
                    </v-col>
                </v-row>
                
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <secondaryButton
                            class=""
                            :props="backBtn"
                        ></secondaryButton>
                    </v-col>
                </v-row>

                

            
                
            </div>

          
        </template>
    </contentCardMobile>


</template>

<script>
import PositionForm from "./PositionForm.vue";
import Utils from '../../../helpers/Utils';
export default {
    components: {
        'position-form': PositionForm,
    },
	data(){
		return {
            ut: Utils,
            serverErrors:{},
            backBtn:{
                text: "Atrás",
                icon: "",
                to: "MyInfo",
                block: true,
                color: 'primary',
                height: "35",
                
                click: ()=>{}
            },
           
            
            loading: false,
            form: {
                id: 176,
                name: "DESARROLLADOR FULL STACK ",
                requires_english: true,
                english_level: "INTERMEDIO ",
                requires_travel: '',
                have_persons_in_charge: '',
                purpose: "",
                area_manager: false,
                rh_manager: false,
                company_id: '',
                company: '',
                code: "INNO -03",
                studies: "Licenciatura",
                experience: "1",
                background: "Conocimiento y experiencia con frameworks basados en PHP.\nExperiencia con desarrollos en tecnologías como nodeJS y Angular. \nExperiencia con integración de APIs REST y SOAP.\nFamiliaridad con metodologías de desarrollo ágil.\nExcelentes habilidades para interpretar y comunicar conceptos afines a su especialidad en diferentes niveles. (técnicos y coloquiales)\nInterés y conocimiento de las tendencias en tecnologías de desarrollo web.",
                abilities: "Proactivo, con pensamiento disruptivo y con actitud de trabajo en equipo.",
                responsibilities: "Desarrollar y dar mantenimiento a aplicaciones con tecnologías web\nCrear RESTful APIs y consumir aquellas de otras plataformas o desarrollos.\nPublicar y/o hacer el despliegue de los proyectos. \nDefinir parámetros técnicos para la implementación de proyectos. \nParticipar de manera activa en los procesos creativos de los proyectos.\nActualizar y consultar de manera regular las actividades correspondientes a su trabajo en la plataforma destinada para ello.",
                authority: "N/A",
                emission_date: '',
                revision: '',
                minimum_age: 0,
                format_key: '',
                format_revision: '',
                department: '',
                report_to: '',
                supervise_to: '',
                made_by: '',
                approved_by: ''
              

            },
            
           
		}
    },
    watch: {
        
     
      
    },
    mounted(){
        // window.addEventListener('resize', this.onResize, { passive: true });
        // this.onResize();
        this.index();
    },
	methods: {
        onActionConfirmed() {
            setTimeout(() => {
                this.$refs.swipeButton.reset();
            }, 1000);
        },
        index(){
            //this.$store.state.overlay = true
			this.loading = true;
            // this.$store.state.loading = true;
			this.$api.employeeEmployee.getPosition()
			.then((response) => {
                this.form = {
                    ...this.form,
                    ...response.data,

                    department: response.data?.department?.name??'',
                    report_to: response.data?.report_to?.name??'',
                    supervise_to: response.data?.supervise_to?.name??'',
                    requires_travel: response.data.requires_travel?'Si':'No',
                    have_persons_in_charge: response.data.have_persons_in_charge?'Si':'No',
                    requires_english: response.data.requires_english?'Si':'No',
                    minimum_age: response.data.minimum_age <= 0 ? '': response.data.minimum_age,
                    made_by: response.data?.made_by?.name??'',
                    approved_by: response.data?.approved_by?.name??'',
                    company: response.data?.company.name??''
                };


                
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				this.loading = false;
                // this.$store.state.loading = false;
                // this.$refs.form.reset();
			})
        },
    },
}
</script>

<style lang="scss">
    
    
    .positionInfo{

        &-subtitles{
            font-size: .7rem;
        }

        &-footer{
            


            &-item{
                display: flex;
                justify-content: space-between;
                font-weight: 600;
                font-size: .7rem;

            }
        }
    }
        
</style>