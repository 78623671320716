<template>
	<div class="surveyResponses">
      
        <!-- survey -->
        <div class="surveyResponses-container" >
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.date = v.trim(); delete serverErrors['date']}" 
                        :valueInput="form.date" 
                        :rule="[]" 
                        label="Fecha de encuesta"
                        :errorMessages="serverErrors['date']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.reason = v.trim(); delete serverErrors['reason']}" 
                        :valueInput="form.reason" 
                        :rule="[]" 
                        label="Motivo de baja"
                        :errorMessages="serverErrors['reason']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm
                        @valueChange="(v) => {values.comments = v.trim(); delete serverErrors.comments}" 
                        :valueInput="values.comments" 
                        :rule="[]" 
                        label="Comentarios sobre la baja"
                        :errorMessages="serverErrors.comments"
                        :rows="1"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_1 = v.trim(); delete serverErrors['question_1']}" 
                        :valueInput="form.question_1" 
                        :rule="[]" 
                        label="¿Recibiste el entrenamiento y/o capacitación adecuados para tu puesto de trabajo?"
                        :errorMessages="serverErrors['question_1']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_2 = v.trim(); delete serverErrors['question_2']}" 
                        :valueInput="form.question_2" 
                        :rule="[]" 
                        label="¿Con frecuencia te proporcionaron nuevas oportunidades de aprendizaje?"
                        :errorMessages="serverErrors['question_2']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_3 = v.trim(); delete serverErrors['question_3']}" 
                        :valueInput="form.question_3" 
                        :rule="[]" 
                        label="¿Qué es lo que más te gustó de trabajar en la empresa?"
                        :errorMessages="serverErrors['question_3']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_4 = v.trim(); delete serverErrors['question_4']}" 
                        :valueInput="form.question_4" 
                        :rule="[]" 
                        label="¿Qué es lo que menos te gustó de trabajar en la empresa?"
                        :errorMessages="serverErrors['question_4']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_5 = v.trim(); delete serverErrors['question_5']}" 
                        :valueInput="form.question_5" 
                        :rule="[]" 
                        label="¿Cuál es el motivo de tu renuncia?"
                        :errorMessages="serverErrors['question_5']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_6 = v.trim(); delete serverErrors['question_6']}" 
                        :valueInput="form.question_6" 
                        :rule="[]" 
                        label="¿Qué consideras que le haga falta a la empresa?"
                        :errorMessages="serverErrors['question_6']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_7 = v.trim(); delete serverErrors['question_7']}" 
                        :valueInput="form.question_7" 
                        :rule="[]" 
                        label="¿Qué consideras que se podría mejorar de tu jefe inmediato?"
                        :errorMessages="serverErrors['question_7']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_8 = v.trim(); delete serverErrors['question_8']}" 
                        :valueInput="form.question_8" 
                        :rule="[]" 
                        label="¿Qué consideras que se podría mejorar de tus compañeros?"
                        :errorMessages="serverErrors['question_8']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            <v-row>
                <v-col class="py-2">
                    <textAreaForm 
                        rows="1"
                        @valueChange="(v) => {form.question_9 = v.trim(); delete serverErrors['question_9']}" 
                        :valueInput="form.question_9" 
                        :rule="[]" 
                        label="¿Consideras que las condiciones de trabajo, las instalaciones y el equipo a tu disposición fueron adecuadas para tus labores?"
                        :errorMessages="serverErrors['question_9']"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>

            <v-row justify="start" >
                <v-col class="text-left pb-0">
                    <div class="font-weight-bold body-2 mb-0">
                        Califica los siguientes aspectos sobre la empresa de acuerdo a tu opinión
                    </div>
                </v-col>
            </v-row>


            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_10"
                        @valueChange="(v) => {delete serverErrors['question_10']}" 
                        label="Comunicación y difusión de la cultura organizacional y los valores de la empresa"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_10']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_11"
                        @valueChange="(v) => {form.question_11 = v; delete serverErrors['question_11']}" 
                        label="Prestaciones para el empleado (vacaciones, ausencia por enfermedades, etc.)"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_11']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_12"
                        @valueChange="(v) => {form.question_12 = v; delete serverErrors['question_12']}" 
                        label="Salario percibido por mis labores"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_12']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_13"
                        @valueChange="(v) => {form.question_13 = v; delete serverErrors['question_13']}" 
                        label="Horario laboral"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_13']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_14"
                        @valueChange="(v) => {form.question_14 = v; delete serverErrors['question_14']}" 
                        label="Efectividad de la comunicación dentro de mi equipo de trabajo (se considera efectiva cuando es clara, ágil y respetuosa)"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_14']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_15"
                        @valueChange="(v) => {form.question_15 = v; delete serverErrors['question_15']}" 
                        label="Disponibilidad de mi jefe inmediato para resolver los problemas dentro del espacio de trabajo"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_15']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_16"
                        @valueChange="(v) => {form.question_16 = v; delete serverErrors['question_16']}" 
                        label="Disposición de mi jefe inmediato para escuchar y tomar en cuenta las opiniones y propuestas de mi equipo"
                        :items="toggleItemsRank"
                        :errorMessage="serverErrors['question_16']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <textAreaForm
                        @valueChange="(v) => {form.question_17 = v.trim(); delete serverErrors.question_17}" 
                        :valueInput="form.question_17" 
                        :rule="[]" 
                        label="Por favor explica más a detalle los motivos de tus respuestas"
                        :errorMessages="serverErrors.question_17"
                        rows="1"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>

            <v-row justify="start" >
                <v-col class="text-left pb-0">
                    <div class="font-weight-bold body-2 mb-0">
                        Responde a las siguientes frases con un sí o no
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_18"
                        @valueChange="(v) => {form.question_18 = v; delete serverErrors['question_18']}" 
                        label="Podría considerar regresar a la empresa en el futuro"
                        :items="toggleItemsYesNo"
                        :errorMessage="serverErrors['question_18']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" class="py-2 ">
                    <ToggleOptionSurveyVue
                        :valueInput="form.question_19"
                        @valueChange="(v) => {form.question_19 = v; delete serverErrors['question_19']}" 
                        label="Recomendaría a una amistad o familiar trabajar en la empresa"
                        :items="toggleItemsYesNo"
                        :errorMessage="serverErrors['question_19']"
                        :readonly="true"
                    />
                    
                </v-col>
            </v-row>


            <v-row>
                <v-col>
                    <textAreaForm
                        @valueChange="(v) => {form.question_20 = v.trim(); delete serverErrors.question_20}" 
                        :valueInput="form.question_20" 
                        :rule="[]" 
                        label="Por favor explica más a detalle los motivos de tus respuestas"
                        :errorMessages="serverErrors.question_20"
                        rows="1"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <textAreaForm
                        @valueChange="(v) => {form.question_21 = v.trim(); delete serverErrors.question_21}" 
                        :valueInput="form.question_21" 
                        :rule="[]" 
                        label="¿Tienes algún comentario u opinión extra que consideres pueda ayudar a mejorar nuestra labor de gestión de personal?"
                        :errorMessages="serverErrors.question_21"
                        rows="1"
                        class="surveyResponses-form-input"
                        :readonly="true"
                    ></textAreaForm>
                </v-col>
            </v-row>
            

            
        </div>
        
	</div>
</template>

<script>
import ToggleOptionSurveyVue from '../../../../components/ToggleOptionSurvey.vue';
import Utils from '../../../../helpers/Utils';
    // import VueGatesPlugin from '../plugins/vue-gates';
	export default {
        props: ['values',],
		name: 'SurveyResponses',
		components: {
            ToggleOptionSurveyVue,
		},
        data() {
            return {
                formKey:83,
                icon:"",
                id: this.$route.params.id,
                employeeName: "",
                employeeNumber: "",
                employeeCompany: "",
                loading:true,
                toggleItemsRank:[
                    {text: "deficiente", value: "deficiente"},
                    {text: "mala", value: "mala"},
                    {text: "regular", value: "regular"},
                    {text: "buena", value: "buena"},
                    {text: "excelente", value: "excelente"},
                ],
                toggleItemsYesNo:[
                    {text: "Si", value: "Si"},
                    {text: "No", value: "No"},
                ],
                serverErrors:{},
                form:{
                    date: '',
                    reason: '',
                    comments: '',
                    question_1: "",
                    question_2: "",
                    question_3: "",
                    question_4: "",
                    question_5: "",
                    question_6: "",
                    question_7: "",
                    question_8: "",
                    question_9: "",
                    question_10: "",
                    question_11: "",
                    question_12: "",
                    question_13: "",
                    question_14: "",
                    question_15: "",
                    question_16: "",
                    question_17: "",
                    question_18: "",
                    question_19: "",
                    question_20: "",
                    question_21: "",
                },

                
            }
        },
        
        mounted(){
            // window.addEventListener('resize', this.onResize, { passive: true });
            // this.onResize();
            this.setformData();
        },
        methods: {
            setformData()
            {
                this.form.date = Utils.globalDateFormat(this.values.created_at?.split("T")[0]??"");
                this.form.reason = this.values?.reason??"";
                this.form.comments = this.values?.comments??"";
                this.form.question_1 = this.values?.survey_answers?.pregunta_1??"";
                this.form.question_2 = this.values?.survey_answers?.pregunta_2??"";
                this.form.question_3 = this.values?.survey_answers?.pregunta_3??"";
                this.form.question_4 = this.values?.survey_answers?.pregunta_4??"";
                this.form.question_5 = this.values?.survey_answers?.pregunta_5??"";
                this.form.question_6 = this.values?.survey_answers?.pregunta_6??"";
                this.form.question_7 = this.values?.survey_answers?.pregunta_7??"";
                this.form.question_8 = this.values?.survey_answers?.pregunta_8??"";
                this.form.question_9 = this.values?.survey_answers?.pregunta_9??"";
                this.form.question_10 = this.values?.survey_answers?.pregunta_10??"";
                this.form.question_11 = this.values?.survey_answers?.pregunta_11??"";
                this.form.question_12 = this.values?.survey_answers?.pregunta_12??"";
                this.form.question_13 = this.values?.survey_answers?.pregunta_13??"";
                this.form.question_14 = this.values?.survey_answers?.pregunta_14??"";
                this.form.question_15 = this.values?.survey_answers?.pregunta_15??"";
                this.form.question_16 = this.values?.survey_answers?.pregunta_16??"";
                this.form.question_17 = this.values?.survey_answers?.pregunta_17??"";
                this.form.question_18 = this.values?.survey_answers?.pregunta_18??"";
                this.form.question_19 = this.values?.survey_answers?.pregunta_19??"";
                this.form.question_20 = this.values?.survey_answers?.pregunta_20??"";
                this.form.question_21 = this.values?.survey_answers?.pregunta_21??"";

            },
            
           
           
            onActionConfirmed() {
                setTimeout(() => {
                    this.$refs.swipeButtonrl.reset();
                }, 1000);
            },

           
        },
	}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
    
    .surveyResponses{
        // background-color: #18585B;
        // min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        // padding: 3rem 0px;
        
        &-container{
            background-color: white;
            width: 100%;
            padding: 2rem 0rem;

            // @media (max-width: #{map-get($grid-breakpoints, 'xl')}) {
            //     padding: 2rem 10rem;
                
            // }

            // @media (max-width: #{map-get($grid-breakpoints, 'lg')}) {
            //     padding: 2rem 2rem;
                
            // }

            // @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            //     padding: 2rem 0rem;
            //     width: 95%;
                
            // }
        }


        &-subtext{
            font-size: 0.75rem;
            font-weight: 500;
            line-height: 1rem !important;
        }

        &-form-input{
            label{
                font-size: .8rem !important;
            }
        }

       
        
    }
	
</style>